import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import type { CartItem, CartType } from "./reducer";
import { cartReducer } from "./reducer";
import { useUser } from "../Auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import type { ProductCardType } from "~/graphql/fragments/types";

export type CartContext = {
  cart: CartType;
  addItemToCart: (product: ProductCardType, quantity: number) => void;
  deleteItemFromCart: (productID: string) => void;
  cartIsEmpty: boolean | undefined;
  clearCart: () => void;
  isProductInCart: (productID: string) => boolean;
  getProductInCart: (productID: string) => CartItem | undefined;
  getProductCartQuantity: (productID: string) => number;
  cartTotal: number;
  hasInitializedCart: boolean;
  taxAmount: number;
};

const Context = createContext({} as CartContext);

export const useCart = () => useContext(Context);

const arrayHasItems = (array: any) => Array.isArray(array) && array.length > 0;

// Step 1: Check local storage for a cart
// Step 2: If there is a cart, fetch the products and hydrate the cart
// Step 3: Authenticate the user
// Step 4: If the user is authenticated, merge the user's cart with the local cart
// Step 4B: Sync the cart to Payload and clear local storage
// Step 5: If the user is logged out, sync the cart to local storage only

export const CartProvider: React.FC<{
  taxRate: number;
  children?: React.ReactNode;
}> = ({ taxRate, children }) => {
  const { t } = useTranslation();
  const { user, token } = useUser();

  const [cart, dispatchCart] = useReducer(cartReducer, {
    items: [],
  });

  const [total, setTotal] = useState<number>(0);
  const [taxAmount, setTaxAmount] = useState<number>(0);

  const hasInitialized = useRef(false);
  const [hasInitializedCart, setHasInitialized] = useState(false);

  // Check local storage for a cart
  // If there is a cart, fetch the products and hydrate the cart
  useEffect(() => {
    if (!hasInitialized.current) {
      hasInitialized.current = true;

      const syncCartFromLocalStorage = async () => {
        const localCart = localStorage.getItem("cart");

        const parsedCart = JSON.parse(localCart || "{}");

        if (parsedCart?.items && parsedCart?.items?.length > 0) {
          const initialCart = await Promise.all(
            parsedCart.items.map(
              async ({
                product,
                quantity,
              }: {
                product: ProductCardType;
                quantity: number;
              }) => {
                const res = await fetch(
                  `${window.ENV.BACKEND_URL}/api/products/${product}`,
                );
                const data = await res.json();

                if (res.ok) {
                  return {
                    product: data,
                    quantity,
                  };
                } else {
                  return null;
                }
              },
            ),
          );

          // filter out any items that failed to fetch
          initialCart.filter(Boolean);

          dispatchCart({
            type: "SET_CART",
            payload: {
              items: initialCart,
            },
          });
        } else {
          dispatchCart({
            type: "SET_CART",
            payload: {
              items: [],
            },
          });
        }
      };

      syncCartFromLocalStorage();
    }
  }, []);

  // authenticate the user and if logged in, merge the user's cart with local state
  // only do this after we have initialized the cart to ensure we don't lose any items
  useEffect(() => {
    if (!hasInitialized.current) return;

    if (user) {
      // merge the user's cart with the local state upon logging in
      dispatchCart({
        type: "MERGE_CART",
        payload: {
          items: (user.cart?.items ?? []).map((item) => ({
            // make sure that the fields of UserFragment and ProductCardFragment match
            product: item.product,
            quantity: item.quantity ?? 0,
          })),
        },
      });
    } else {
      // clear the cart from local state after logging out
      dispatchCart({
        type: "CLEAR_CART",
      });
    }
  }, [user]);

  // every time the cart changes, determine whether to save to local storage or Payload based on authentication status
  // upon logging in, merge and sync the existing local cart to Payload
  useEffect(() => {
    // wait until we have attempted authentication (the user is either an object or `null`)
    if (!hasInitialized.current) return;

    // ensure that cart items are fully populated, filter out any items that are not
    // this will prevent discontinued products from appearing in the cart
    const flattenedCart = {
      ...cart,
      items: cart?.items
        ?.map((item: CartItem) => {
          if (!item?.product || typeof item?.product !== "object") {
            return null;
          }

          return {
            ...item,
            // flatten relationship to product
            product: item?.product?.id,
            quantity: typeof item?.quantity === "number" ? item?.quantity : 0,
          };
        })
        .filter(Boolean) as CartItem[],
    };

    if (user?.id) {
      try {
        const syncCartToPayload = async () => {
          const req = await fetch(
            `${window.ENV.BACKEND_URL}/api/users/${user.id}`,
            {
              method: "PATCH",
              body: JSON.stringify({
                cart: flattenedCart,
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            },
          );

          if (req.ok) {
            localStorage.setItem("cart", "[]");
          }
        };

        syncCartToPayload();
      } catch (e) {
        console.error("Error while syncing cart to Payload."); // eslint-disable-line no-console
      }
    } else {
      localStorage.setItem("cart", JSON.stringify(flattenedCart));
    }

    setHasInitialized(true);
  }, [user, cart, token]);

  const getProductInCart = useCallback(
    (productID: string): CartItem | undefined => {
      const { items: itemsInCart } = cart || {};
      if (Array.isArray(itemsInCart) && itemsInCart.length > 0) {
        return itemsInCart.find(({ product }) =>
          typeof product === "string"
            ? product === productID
            : product?.id === productID,
        );
      }
    },
    [cart],
  );

  const isProductInCart = useCallback(
    (productID: string): boolean => {
      return Boolean(getProductInCart(productID));
    },
    [getProductInCart],
  );

  const getProductCartQuantity = useCallback(
    (productID: string): number => {
      const product = getProductInCart(productID);
      return product?.quantity || 0;
    },
    [getProductInCart],
  );

  // this method can be used to add new items AND update existing ones
  const addItemToCart = useCallback(
    (product: ProductCardType, quantity: number) => {
      dispatchCart({
        type: "ADD_ITEM",
        payload: {
          product,
          quantity,
        },
      });
      toast.success(t("cart.notification.added"));
    },
    [t],
  );

  const deleteItemFromCart = useCallback(
    (productID: string) => {
      dispatchCart({
        type: "DELETE_ITEM",
        productID,
      });
      toast.success(t("cart.notification.removed"));
    },
    [t],
  );

  const clearCart = useCallback(() => {
    dispatchCart({
      type: "CLEAR_CART",
    });
    toast.success(t("cart.notification.cleared"));
  }, [t]);

  // calculate the new cart total whenever the cart changes
  useEffect(() => {
    if (!hasInitialized) return;

    setTotal(
      cart?.items?.reduce((acc: any, item: CartItem) => {
        return (
          acc +
          (typeof item.product === "object"
            ? (item.product?.price ?? 0) * (item.quantity ?? 0)
            : 0)
        );
      }, 0) || 0,
    );

    setTaxAmount(
      cart?.items?.reduce((acc: number, item: CartItem) => {
        return (
          acc +
          (typeof item.product === "object"
            ? item.product?.allowTaxExemption
              ? 0
              : (item.product?.price ?? 0) * (item.quantity ?? 0) * taxRate
            : 0)
        );
      }, 0) ?? 0,
    );
  }, [cart, hasInitialized, taxRate]);

  return (
    <Context.Provider
      value={{
        cart,
        addItemToCart,
        deleteItemFromCart,
        cartIsEmpty: hasInitializedCart && !arrayHasItems(cart?.items),
        clearCart,
        isProductInCart,
        getProductInCart,
        getProductCartQuantity,
        cartTotal: total,
        hasInitializedCart,
        taxAmount,
      }}
    >
      {children && children}
    </Context.Provider>
  );
};
